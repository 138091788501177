<template>
  <div class="card-card" v-bind:class="rootClassName">
    <div class="card-info">
      <span class="card-text">{{ text }}</span>
      <span class="card-text1">{{ text1 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    text1: {
      type: String,
      default:
          'Amet minim mollit non deserunt ullamco est sit aliqua dolor do.',
    },
    rootClassName: String,
    text: {
      type: String,
      default: 'Business',
    },
  },
}
</script>

<style scoped>
.card-card {
  flex: 0 0 auto;
  width: 32%;
  display: flex;
  padding: 20px;
  max-width: 450px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/rectangle%2035-1500w.png");
}
.card-info {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-scheme-white);
}
.card-text {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.card-text1 {
  line-height: 1.5;
}

.card-root-class-name1 {
  background-size: cover;
  background-image: url("/rectangle%2035%20%5B2%5D-200h.png");
}
.card-root-class-name2 {
  background-size: cover;
  background-image: url("/rectangle%2035%20%5B1%5D-200h.png");
}
@media(max-width: 767px) {
  .card-card {
    width: 100%;
  }
}
</style>
