<template>
  <div class="home-container">
    <div class="home-navbar navbar-container">
      <div class="max-width">
        <header data-thq="thq-navbar" class="home-navbar-interactive">
          <div class="home-logo"><img alt="image" src="/group%202.svg" class="home-image"/><span
              class="brandName"><span class="home-text001">厦门</span><span>序列化科技有限公司</span></span></div>

          <div data-thq="thq-navbar-nav" class="home-desktop-menu">
            <div class="home-links">
<!--              <span class="home-text003 navbarLink">How it works</span>-->
<!--              <span class="home-text004 navbarLink">Features</span>-->
<!--              <span class="home-text005 navbarLink">Blog</span>-->
<!--              <span class="home-text006 navbarLink">Pricing</span>-->
<!--              <button class="button-secondary button">Log in</button>-->
              <button class="button button-primary">关于我们</button>
            </div>
          </div>
          <!--
          <div data-thq="thq-burger-menu" class="home-burger-menu">
            <svg viewBox="0 0 1024 1024" class="home-icon">
              <path
                  d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </div>
          -->
<!--          <div data-thq="thq-mobile-menu" class="home-mobile-menu">-->
<!--            <div class="home-nav"-->
<!--            >-->
<!--              <div class="home-top"-->
<!--              >-->
<!--                <div class="home-logo1"-->
<!--                ><img-->
<!--                    alt="image"-->
<!--                    src="/group%202.svg"-->
<!--                    class="home-image1"-->
<!--                /><span class="brandName"-->
<!--                ><span class="home-text008">UP -</span-->
<!--                ><span> START</span></span-->
<!--                ></div-->
<!--                >-->
<!--                <div data-thq="thq-close-menu" class="home-close-menu"-->
<!--                >-->
<!--                  <svg viewBox="0 0 1024 1024" class="home-icon02">-->
<!--                    <path-->
<!--                        d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"-->
<!--                    ></path>-->
<!--                  </svg>-->
<!--                </div>-->
<!--              </div-->
<!--              >-->
<!--              <nav class="home-links1"-->
<!--              ><span class="home-text010 navbarLink">About</span-->
<!--              ><span class="home-text011 navbarLink">Features</span-->
<!--              ><span class="home-text012 navbarLink">Pricing</span-->
<!--              ><span class="home-text013 navbarLink">Team</span-->
<!--              ><span class="home-text014 navbarLink">Blog</span></nav-->
<!--              >-->
<!--              <div class="home-buttons"-->
<!--              >-->
<!--                <button class="button-secondary button">Log in-->
<!--                </button-->
<!--                >-->
<!--                <button class="button button-primary">Get started</button>-->
<!--              </div-->
<!--              >-->
<!--            </div-->
<!--            >-->
<!--            <div-->
<!--            >-->
<!--              <svg viewBox="0 0 950.8571428571428 1024" class="home-icon04">-->
<!--                <path-->
<!--                    d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"-->
<!--                ></path>-->
<!--              </svg-->
<!--              >-->
<!--              <svg viewBox="0 0 877.7142857142857 1024" class="home-icon06">-->
<!--                <path-->
<!--                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"-->
<!--                ></path>-->
<!--              </svg-->
<!--              >-->
<!--              <svg viewBox="0 0 602.2582857142856 1024" class="home-icon08">-->
<!--                <path-->
<!--                    d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"-->
<!--                ></path>-->
<!--              </svg>-->
<!--            </div>-->
<!--          </div>-->
        </header>
      </div>
    </div
    >

    <div class="hero-container section-container"
    >
      <div class="home-max-width1 max-width"
      >
        <div class="home-content"><span class="home-subtitle beforeHeading">时钟</span>
          <h1 class="home-title"
          ><span>熊猫时钟
              <span v-html="raw82z3"></span></span><span class="home-text016">体验</span></h1><span class="home-description"
          >为小朋友学习时钟而设计的</span>
          <div class="home-container01"
          >
<!--            <button class="button button-gradient">Get started</button>-->
<!--            <button class="button button-transparent">Log in</button>-->
          </div>
        </div>
        <div class="home-image2">
          <img alt="image" src="/icon.jpg" class="home-hero-image" style="margin-top:30px;"/>
<!--          <img-->
<!--            alt="image"-->
<!--            src="/union-400w.png.txt"-->
<!--            class="home-graphic-top"/>-->
<!--          <img-->
<!--            alt="image"-->
<!--            src="/group%2018-1200w.png"-->
<!--            class="home-image3"/>-->
        </div>
      </div>
    </div
    >
    <!--
   <div class="section-container">
     <div class="home-max-width2 max-width">
       <div class="home-image4"><img
           alt="image"
           src="/group%2020-1200w.png"
           class="home-hero-image1"/></div>
       <div class="home-content1"><span class="home-text017 beforeHeading">how it works</span>
         <h1 class="home-text018"><span>Pay and get paid <span v-html="rawnvqa"></span></span><span class="home-text020">faster <span v-html="raw8lqq"></span></span><span>than ever.</span></h1><span class="home-text022">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
           sint. Velit officia consequat duis enim velit mollit. Exercitation
           veniam consequat sunt nostrud amet.</span>
         <div class="home-container02">
           <button class="button-secondary button bg-transparent">Learn more
           </button>
         </div>
       </div>
     </div>
   </div>
   <div class="home-section1 section-container">
     <div class="home-max-width3 max-width">
       <div class="home-content2"><span class="home-text023 beforeHeading">save money</span>
         <h1 class="home-text024"><span class="home-text025">Earn cashback</span><br/><span
             class="home-text027">from your favorite brands</span></h1><span class="home-text028">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
           sint. Velit officia consequat duis enim velit mollit. Exercitation
           veniam consequat sunt nostrud amet.</span>
         <div class="home-container03">
           <button class="button-secondary button bg-transparent">See how
           </button>
         </div>
       </div>
       <div class="home-image5"><img
           alt="image"
           src="/group%2021-1200w.png"
           class="home-hero-image2"/>
       </div>
     </div>
   </div>
   <div class="home-section2 section-container">
     <div class="home-max-width4 max-width">
       <div class="home-image6"><img
           alt="image"
           src="/jc-gellidon-fnc3yctccoi-unsplash%2014%20%5B1%5D-1200w.png"
           class="home-hero-image3"/>
       </div>
       <div class="home-content3"><span class="home-text029 beforeHeading">get started</span>
         <h1 class="home-text030">Open your account today</h1>
         <div class="home-step">
           <div class="home-number"><span class="home-text031">1</span></div>
           <div class="home-container04"><span class="home-title1">Download UpStart App</span>
             <span class="home-text032">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
               amet sint. Velit officia consequat duis enim velit mollit.</span>
           </div>
         </div>
         <div class="home-step1">
           <div class="home-number1"><span class="home-text033">2</span></div>
           <div class="home-container05"><span class="home-title2">Create your free account</span>
             <span class="home-text034">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
               amet sint. Velit officia consequat duis enim velit mollit.</span>
           </div>
         </div>
         <div class="home-step2">
           <div class="home-number2"><span class="home-text035">3</span></div>
           <div class="home-container06"><span class="home-title3">Link your existing credit cards</span>
             <span class="home-text036">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
               amet sint. Velit officia consequat duis enim velit mollit.</span>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="section-container">
     <div class="home-max-width5 max-width"><span class="home-text037 beforeHeading">get started</span>
       <h1 class="home-text038"><span>No matter what you do,</span><br/><span>UpStart will save you money</span></h1>
       <div class="home-cards-container">
         <app-card rootClassName="card-root-class-name"></app-card>
         <app-card
             text="Personal"
             rootClassName="card-root-class-name2"></app-card
         >
         <app-card
             text="Family"
             rootClassName="card-root-class-name1"></app-card>
       </div>
     </div>
   </div>
   <div class="home-section4 section-container">
     <div class="home-max-width6 max-width">
       <div class="home-faq">
         <div class="home-questions"><span class="home-text041 beforeHeading">faq</span>
           <h1 class="home-text042"><span class="home-text043">Frequently Asked <span v-html="raw5qd2"></span></span
           ><br/><span class="home-text045">Questions</span></h1
           >
           <div data-role="Accordion" class="question">
             <div data-type="accordion-header" class="home-trigger"><span class="home-text046">Is this a Free or Paid service?</span
             >
               <svg viewBox="0 0 1024 1024" class="home-icon10">
                 <path
                     d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
               </svg>
             </div>
             <div data-type="accordion-content" class="question-content"><span class="home-text047">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                 amet sint. Velit officia consequat duis enim velit mollit.</span
             ></div>
           </div>
           <div data-role="Accordion" class="question">
             <div data-type="accordion-header" class="home-trigger1"><span class="home-text048">Do you operate in United Stated?</span
             >
               <svg viewBox="0 0 1024 1024" class="home-icon12">
                 <path
                     d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
               </svg>
             </div>
             <div data-type="accordion-content" class="question-content"><span class="home-text049">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                 amet sint. Velit officia consequat duis enim velit mollit.</span
             ></div>
           </div>
           <div data-role="Accordion" class="question">
             <div data-type="accordion-header" class="home-trigger2"><span class="home-text050">Is this a globally available bank?</span
             >
               <svg viewBox="0 0 1024 1024" class="home-icon14">
                 <path
                     d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
               </svg>
             </div>
             <div data-type="accordion-content" class="question-content"><span class="home-text051">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                 amet sint. Velit officia consequat duis enim velit mollit.</span
             ></div>
           </div>
           <div data-role="Accordion" class="question">
             <div data-type="accordion-header" class="home-trigger3"><span class="home-text052">Do you have an iOS or Android app?</span
             >
               <svg viewBox="0 0 1024 1024" class="home-icon16">
                 <path
                     d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
               </svg>
             </div>
             <div data-type="accordion-content" class="question-content"><span class="home-text053">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                 amet sint. Velit officia consequat duis enim velit mollit.</span
             ></div>
           </div>
         </div>
         <img alt="image" src="/group%202-1200w.png" class="home-image7"></div>
       <div class="home-banner"><span class="home-text054 beforeHeading">get started</span>
         <h1 class="home-text055"><span>Push your finances to</span><br/><span>the next level.</span></h1><span class="home-text059"><span>Amet minim mollit non deserunt ullamco est sit
             <span v-html="raw9thk"></span></span><br/><span>aliqua dolor do amet sint.
             <span v-html="rawl9nl"></span></span></span>
         <div class="home-btns">
           <button class="home-button08 button button-transparent">See all plans
           </button
           >
           <button class="home-button09 button button-gradient">Get started
           </button
           >
         </div>
       </div>
     </div>
   </div
   >
   -->
    <footer class="home-footer">
      <div class="home-links-container">
        <div class="home-container07">
          <div class="footer-column"><span class="home-text063">产品</span><span class="home-text064">如何工作？</span><span class="home-text065">特征</span><span class="home-text066">价格</span><span>FAQ</span></div>
          <div class="footer-column"><span class="home-text069">应用</span><span class="home-text070">下载iOS</span></div>
          <div class="footer-column"><span class="home-text075">公司</span><span class="home-text076">关于我们</span><span>联系</span></div>
<!--          <div class="footer-column"><span class="home-text082">Invest</span><span class="home-text083">Commodity</span><span class="home-text084">Savings</span><span class="home-text085"><span>Taxes and fees</span><br/><span></span></span><span class="home-text088"><span>Currency exchange</span></span><span>Community</span></div>-->
<!--          <div class="footer-column"><span class="home-text091">Security</span><span class="home-text092">Security status</span><span class="home-text093">ISO</span><span class="home-text094">System status</span><span>Customer Help</span></div>-->
<!--          <div class="footer-column"><span class="home-text096">Follow</span><span class="home-text097">Instagram</span><span class="home-text098">Twitter</span><span class="home-text099">Facebook</span><span class="home-text100">Tik Tok</span><span class="home-text101">Linkedln</span><span>Youtube</span></div>-->
        </div>
      </div>
    </footer>
    <div style="margin-bottom:20px;text-align: center;">
        <span><a target="_blank" href="https://beian.miit.gov.cn">闽ICP备2024066474号</a></span><br>
        <span><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302036318" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="beian.png" style="float:left;"><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">闽公网安备35020302036318号</p></a></span>
    </div>

  </div>
</template>

<script>
import AppCard from '../components/card'

export default {
  name: 'Home',
  props: {},
  components: {
    AppCard,
  },
  data() {
    return {
      raw82z3: ' ',
      rawnvqa: ' ',
      raw8lqq: ' ',
      raw5qd2: ' ',
      raw9thk: ' ',
      rawl9nl: ' ',
    }
  },
  metaInfo: {
    title: 'Up Start Template',
    meta: [
      {
        property: 'og:title',
        content: 'Up Start Template',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.home-navbar {
  align-items: center;
  flex-direction: column;
}

.home-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-image {
  width: 22px;
  object-fit: cover;
  margin-right: 14px;
}

.home-text001 {
  color: var(--dl-color-scheme-orange100);
}

.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.home-links {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-text003 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
}

.home-text004 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
}

.home-text005 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
}

.home-text006 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
}

.home-burger-menu {
  display: none;
}

.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.home-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-image1 {
  width: 22px;
  object-fit: cover;
  margin-right: 14px;
}

.home-text008 {
  color: var(--dl-color-scheme-orange100);
}

.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.home-text010 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text011 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text012 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text013 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text014 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-buttons {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-max-width1 {
  align-items: center;
}

.home-content {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}

.home-subtitle {
  color: var(--dl-color-scheme-orange100);
  margin-bottom: var(--dl-space-space-unit);
}

.home-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-text016 {
  color: var(--dl-color-scheme-lightgreen);
}

.home-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.home-image2 {
  position: relative;
  padding-bottom: var(--dl-space-space-eightunits);
}

.home-hero-image {
  flex: 1;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.home-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}

.home-image3 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}

.home-max-width2 {
  align-items: stretch;
}

.home-hero-image1 {
  object-fit: contain;
  margin-left: -250px;
  border-radius: 48px;
}

.home-content1 {
  width: 50%;
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}

.home-text017 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}

.home-text018 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-text020 {
  color: var(--dl-color-scheme-orange100);
}

.home-text022 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.home-section1 {
  background-color: rgba(255, 199, 139, 0.14);
}

.home-max-width3 {
  align-items: stretch;
}

.home-content2 {
  width: 50%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}

.home-text023 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}

.home-text024 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-text025 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}

.home-text027 {
  font-family: Urbanist;
}

.home-text028 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.home-hero-image2 {
  object-fit: contain;
  margin-right: -250px;
  border-radius: 48px;
}

.home-section2 {
  background-color: var(--dl-color-scheme-green100);
}

.home-max-width4 {
  align-items: stretch;
}

.home-image6 {
  width: 50%;
}

.home-hero-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 48px;
}

.home-content3 {
  width: 40%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}

.home-text029 {
  color: var(--dl-color-scheme-white);
  margin-bottom: var(--dl-space-space-unit);
}

.home-text030 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-step {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-number {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}

.home-text031 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}

.home-container04 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-title1 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text032 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}

.home-step1 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}

.home-text033 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}

.home-container05 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-title2 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text034 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}

.home-step2 {
  display: flex;
  flex-direction: row;
}

.home-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}

.home-text035 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}

.home-container06 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-title3 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text036 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}

.home-max-width5 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text037 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text038 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.home-section4 {
  padding-top: 0px;
}

.home-max-width6 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-faq {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-eightunits);
  flex-direction: row;
  justify-content: space-between;
}

.home-questions {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-text041 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}

.home-text042 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-text043 {
  font-family: Urbanist;
}

.home-text045 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}

.home-trigger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-text046 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}

.home-icon10 {
  width: 12px;
  height: 12px;
}

.home-text047 {
  margin-top: 8px;
}

.home-trigger1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-text048 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}

.home-icon12 {
  width: 12px;
  height: 12px;
}

.home-text049 {
  margin-top: 8px;
}

.home-trigger2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-text050 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}

.home-icon14 {
  width: 12px;
  height: 12px;
}

.home-text051 {
  margin-top: 8px;
}

.home-trigger3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-text052 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}

.home-icon16 {
  width: 12px;
  height: 12px;
}

.home-text053 {
  margin-top: 8px;
}

.home-image7 {
  width: 50%;
  object-fit: cover;
  margin-right: -200px;
}

.home-banner {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/group%2011-1200w.png");
}

.home-text054 {
  color: var(--dl-color-scheme-lightgreen);
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text055 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Urbanist";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text059 {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-btns {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.home-button08 {
  font-size: 16px;
  padding-top: 12px;
  margin-right: var(--dl-space-space-unit);
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
}

.home-button09 {
  padding-top: 12px;
  margin-right: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
}

.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.home-links-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-container07 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.home-text063 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text064 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text065 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text066 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text067 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text069 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text070 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text071 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text072 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text073 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text075 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text076 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text077 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text078 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text079 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text080 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text082 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text083 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text084 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text085 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text088 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text091 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text092 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text093 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text094 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text096 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text097 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text098 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text099 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text100 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text101 {
  margin-bottom: var(--dl-space-space-unit);
}

@media (max-width: 991px) {
  .home-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }

  .home-image2 {
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-hero-image {
    margin-bottom: 0px;
  }

  .home-max-width2 {
    flex-direction: column;
  }

  .home-image4 {
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-hero-image1 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .home-content1 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .home-max-width3 {
    flex-direction: column;
  }

  .home-content2 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }

  .home-image5 {
    padding-bottom: 0px;
  }

  .home-hero-image2 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .home-max-width4 {
    flex-direction: column;
  }

  .home-image6 {
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-hero-image3 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .home-content3 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .home-max-width5 {
    flex-direction: column;
  }

  .home-max-width6 {
    flex-direction: column;
  }

  .home-questions {
    width: 60%;
  }

  .home-image7 {
    margin-right: -100px;
  }

  .home-footer {
    flex-direction: column;
  }

  .home-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: row;
  }

  .home-container07 {
    flex-wrap: wrap;
    margin-right: 0px;
  }
}

@media (max-width: 767px) {
  .home-desktop-menu {
    display: none;
  }

  .home-links {
    display: none;
  }

  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-text010 {
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-text011 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-text012 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-text013 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-text014 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-max-width1 {
    flex-direction: column-reverse;
  }

  .home-content {
    width: 100%;
    margin-right: 0px;
  }

  .home-hero-image {
    margin-right: 0px;
  }

  .home-content1 {
    width: 100%;
    margin-right: 0px;
  }

  .home-content2 {
    width: 100%;
    margin-right: 0px;
  }

  .home-content3 {
    width: 100%;
    margin-right: 0px;
  }

  .home-cards-container {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-wrap: wrap;
    justify-content: center;
  }

  .home-faq {
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }

  .home-questions {
    width: 100%;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-image7 {
    width: 100%;
    margin-right: 0px;
  }

  .home-banner {
    padding: var(--dl-space-space-twounits);
  }

  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-container07 {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 479px) {
  .home-mobile-menu {
    padding: 16px;
  }

  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-footer {
    padding: var(--dl-space-space-unit);
  }

  .home-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }

  .home-container07 {
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
  }
}
</style>
